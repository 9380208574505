 <!-- <section sourceSense class="bg-primary stats-section-1 text-white text-center sec-padding-tb-30 ">
    <div class="container text-center  counter ">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <h1 class="text-uppercase">300+ Million <br class="visible-xs"> Cars Parked</h1>
                <p class="hidden-xs showOnClick" style="font-size: 16px;">
                    Just about anywhere you go in Manhattan, you’re not far from a safe and convenient Icon parking
                    facility. Icon, combined with Quik Park, is the largest parking operator in Manhattan with over 200
                    special locations and more than 50 years of experience. “PARKING IS JUST THE START<sup>TM</sup>”, a New York
                    company serving the premier needs of one of the best cities in the world."
                </p>
                <a (click)="showHideData()" href="javascript:;"
                    class="btn-300-text btn visible-xs btn-link btn-block text-white">Learn More</a>
            </div>
        </div>

        <div class="row hidden ">
            <div class="col-xs-12 col-sm-4 col-md-4 counter border-right ">
                <h1><span>200</span>+</h1>
                <h4>Locations</h4>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 counter border-right ">
                <h1><span>40K</span>+</h1>
                <h4>Cars Parked Daily</h4>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 counter ">
                <h1><span>50</span>+</h1>
                <h4>Years in business</h4>
            </div>
        </div>
    </div>
</section> -->
 <footer class="sec-padding-tb-40 ">
    <div class="container pt-4 pb-4 ">
        <div class="row ">
            <div class="col-sm-4 ">
                <div class="logo-part ">
                    <img [src]="icon_logo " width="100 " class="ftr-logo ">
                    <!-- <img [src]="qp_Logo " width="50 " class="ml-4 "> -->
                    
                    <div class="footer-social ">
                        <p class=" ">
                            <a class="socialIcon " target="_blank" [href]="instagramUrl"><img [src]="instagramLogo" alt="icon "></a>
                            <a class="socialIcon " target="_blank" [href]="facebookUrl"><img [src]="fbLogo" alt="icon "></a>
                            <a class="socialIcon " target="_blank" [href]="twitterUrl"><img [src]="twiterLogo" alt="icon "></a>
                        </p>
                        <h4>PARKING IS JUST THE START</h4>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 ">
                <div class="row ">
                    <div class="col-md-7 ">
                        <div class="row ">

                            <div class="col-md-4 visible-xs mar-bottom-sm ">
                                <div class="footerAppButton">
                                    <div class="flex-row no-gutters">
                                        <div class="col-auto">
                                            <img class="alterImg" src="../../../assets/img/new-homepage/arrow-list-icongo-wt.svg">
                                        </div>
                                        <div class="col">
                                            <h4>
                                             PARK LIKE A VIP,<br>
                                             <span class="text-primary">DOWNLOAD THE APP</span>
                                            </h4>
                                        </div>
         
                                    </div>
                                    <p>
                                     Icon GO app offers a level of service 
                                     you just won't find anywhere else. 
                                    </p>
                                    <ul>
                                        <li>
                                            <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Direct booking discounts
                                        </li>
                                        <li>
                                         <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Seamless check in and check out
                                     </li>
                                     <li>
                                         <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Exclusive services and features
                                     </li>
                                    </ul>
                                    <p>
                                        Download the app
                                    </p>
                                    <div class="flex-row no-gutters">
                                        <div class="col">
                                            <a target="_blank" [href]="appStoreUrl" class="appstore-img  "><img alt="App Store " [src]="appstore" class="img-responsive" ></a>
        
                                        </div>
                                        <div class="col">
                                            <a target="_blank" [href]="googlePlayUrl"><img alt="Google play " [src]="gstore " class="img-responsive"></a>
        
                                        </div>
                                  
                                    </div>
                                 </div>

                            </div>
                            <div class="col-sm-6 col-md-6 ">
                                <ul>
                                    <li> <a target="_blank" href="{{redirectUrl}}/cms/news" >news + tips</a> </li>
                                    <li> <a target="_blank" href="{{redirectUrl}}/monthly-parking" >monthly parking</a></li>
                                    <li> <a target="_blank" href="{{redirectUrl}}/contact" >contact us</a></li>
                                    <li><a  target="_blank" href="{{redirectUrl}}/faq" >faq</a></li>
                                </ul>
                            </div>
                            <div class="col-sm-6 col-md-6">
                                <ul>
                                    <li>
                                        <a  target="_blank" href='{{redirectUrl}}'>login</a>
                                    </li>
                                    <!-- <li *ngIf="isLoggedIn">
                                        <a target="_blank" routerLink="/my-account/account">my account</a>
                                    </li> -->
                                    <li>
                                        <a target="_blank" href="{{redirectUrl}}/about-us">About Us</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="{{redirectUrl}}/evcharging" >EV Charging</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="{{redirectUrl}}/careers" >careers</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 hidden-xs ">
                        <div class="footerAppButton">
                            <div class="flex-row no-gutters">
                                <div class="col-auto">
                                    <img class="alterImg" src="../../../assets/img/new-homepage/arrow-list-icongo-wt.svg">
                                </div>
                                <div class="col">
                                    <h4>
                                     PARK LIKE A VIP,<br>
                                     <span class="text-primary">DOWNLOAD THE APP</span>
                                    </h4>
                                </div>
                            </div>
                            <p>
                             Icon GO app offers a level of service 
                             you just won't find anywhere else. 
                            </p>
                            <ul>
                                <li>
                                    <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Direct booking discounts
                                </li>
                                <li>
                                 <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Seamless check in and check out
                             </li>
                             <li>
                                 <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Exclusive services and features
                             </li>
                            </ul>
                            <p>
                                Download the app
                            </p>
                            <div class="flex-row no-gutters">
                                <div class="col">
                                    <a target="_blank" [href]="appStoreUrl" class="appstore-img  "><img alt="App Store " [src]="appstore" class="img-responsive" ></a>
                                </div>
                                <div class="col">
                                    <a target="_blank" [href]="googlePlayUrl"><img alt="Google play " [src]="gstore " class="img-responsive"></a>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <p class="ftr-copy ">
                            <small>© 2023 Icon Parking - All Rights Reserved.  <br class="visible-xs" ><a target="_blank" href="{{redirectUrl}}/terms-of-service">Terms of Service</a> & <a target="_blank" href="{{redirectUrl}}/terms-of-service">Privacy Policy</a></small></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
